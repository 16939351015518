import tailwind from '@/styles/tailwind.css?inline';
import themes from '@/styles/themes.css?inline';

const componentStyles = import.meta.glob(
  '@/components/**/*.css',
  { import: 'default', query: '?inline', eager: true },
);

export const styles = [
  tailwind,
  themes,
  ...Object.values(componentStyles),
].join('\n');
